import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { UserState } from '../store/reducers/userReducer';

const EditorRoute = () => {

    const user: UserState = useSelector((state: any) => state.user);

    if(user.isLoggedIn && !user.userData) {
        return null;
    }

    return <Outlet />;
}

export default EditorRoute;