import { useFocused, useSelected } from "slate-react"

const ImageElement = ({ attributes, children, element, style }: any) => {
    const selected = useSelected();
    const focused = useFocused();

    return (
        element.url &&
        <div
            className="image-container"
            style={{
                justifyContent: style.textAlign || 'flex-start',
                display: 'flex',
                height: `${element?.dimensions?.height}px`,
                width: '100%'//`${element?.dimensions?.width}px`
            }}
            {...attributes}>
            {children}
            <img
                src={element.url}
                alt={imageName(element.url)}
                className={`image-element ${selected && focused ? 'selected' : ''}`} />
        </div>
    )
}

const imageName = (url: string) => {
    const nameWithExt = url.substring(url.lastIndexOf('/') + 1);
    const name = nameWithExt.split('.')[0];
    return name || 'image';
}

export default ImageElement;