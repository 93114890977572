import { useParams, useNavigate } from "react-router-dom";
import TextEditor from "../../components/TextEditor";
import { Fragment, useEffect, useState } from "react";
import { uniqueIdGenerator } from "../../utils/uniqueId";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { NoteState, fetchNote, noteOpen } from "../../store/reducers/noteReducer";
import NoteValidator from "../../components/NoteValidator";
import PageMetaData from "../../components/PageMetaData";
import { startLoading, stopLoading } from "../../store/reducers/loaderReducer";
import { UserState } from "../../store/reducers/userReducer";
import NotePostEditor from "../../components/TextEditor/NotePostEditor";

function Home() {

  const { noteLinkID } = useParams();
  const navigate = useNavigate();
  const [noteId, setNoteId] = useState<string | undefined>(noteLinkID || '');
  const note: NoteState = useSelector((state: any) => state.note);
  const dispatch: any = useDispatch();
  const user: UserState = useSelector((state: any) => state.user);

  const textEditor = () => {
    return <TextEditor noteLinkID={noteId} noteData={note.data} userData={user.userData} />;
  }

  const viewSelection = () => {
    switch (note.type) {
      case 'published':
        if (user?.userData && user?.userData?._id === note?.data?.owner?._id)
          return textEditor();
        return <NotePostEditor note={note.data!} document={note.document!} />;
      case 'locked':
        return <NoteValidator noteData={note.data} />;
      case 'default':
        return textEditor();
    }
  }

  useEffect(() => {
    if (!noteLinkID) {
      const uniqueParam = uniqueIdGenerator();
      navigate(`/${uniqueParam}`, { replace: false });
      setNoteId(uniqueParam);
    } else if (noteLinkID !== noteId) {
      setNoteId(noteLinkID);
    }
  }, [noteLinkID]);

  useEffect(() => {
    if (noteId)
      dispatch(fetchNote({ noteId, userId: user?.userData?._id }));
  }, [noteId]);

  useEffect(() => {
    dispatch(note.status === 'loading' ? startLoading() : stopLoading());
  }, [note.status]);

  useEffect(() => {
    dispatch(noteOpen(true));
    return () => dispatch(noteOpen(false));
  }, [note.isNoteOpen]);

  return (
    <Fragment>
      <PageMetaData
        route={noteLinkID!}
        title={note?.data?.metaTitle || 'Note'}
        description={note?.data?.metaDescription}
        author={note.data?.owner?.name}
        date={note?.data?.updatedOn}
        isStructuredData={note?.data?.isSEO} />

      <div className="home">
        {viewSelection()}
      </div>
    </Fragment>
  );
}

export default Home;
