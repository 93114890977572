import { createSlice } from '@reduxjs/toolkit';
import { storage } from '../../utils/storage';

interface ThemeState {
  darkMode: boolean;
}

const initialState: ThemeState = {
  darkMode: storage.local.getItem('theme') === 'dark'
};

const themeReducer = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    toggleTheme: (state) => {
      const newMode = !state.darkMode;
      storage.local.setItem('theme', newMode ? 'dark' : 'light');
      state.darkMode = newMode;
    },
  },
});

export const { toggleTheme } = themeReducer.actions;

export default themeReducer.reducer;