import { Col, Row, Skeleton } from "antd";
import { Fragment } from "react";

function NoteSkeleton() {
    return (
        <Fragment>
            <div className="stack-header">
                <Skeleton paragraph={{ rows: 1, width: '80%', style: {marginTop: '18px'} }} active />
            </div>
            <div className="contain">
                <div className="public-notes">
                    <Row gutter={[14, 14]}>
                        {[1, 2].map((pos: number, index: number) => (
                            <Col key={index} span={24} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <div className="note-card">
                                    <div className="note-header">
                                        <Skeleton paragraph={{ rows: 1, width: '80%', style: {marginTop: '18px'} }} active />
                                    </div>
                                    <div className="note-footer">
                                        <Skeleton title={false} paragraph={{ rows: 1 }} style={{ width: '30%' }} active />
                                        <div className="note-link">
                                            <Skeleton.Button size={"small"} shape={"square"} active />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>
            </div>
        </Fragment>
    )
}

export default NoteSkeleton;