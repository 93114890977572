import axiosHttp from ".";
import { API } from "../apis";
import { setAccessToken } from "../store/reducers/userReducer";
import logError from "./errorHandler";

const TOKEN_TYPE = 'Bearer';

function runInterceptors(store: any) {
    axiosHttp.interceptors.request.use(
        config => {
            const token = store.getState()?.user?.accessToken;
            if (token) {
                config.headers.Authorization = `${TOKEN_TYPE} ${token}`;
            }
            return config;
        },
        error => {
            logError(error, store);
            return Promise.reject(error);
        }
    );

    function attachResponseInterceptor() {
        const responseInterceptor = axiosHttp.interceptors.response.use(
            (response) => response,
            async (error) => {
                if (error.response) {
                    const config = error?.config;
                    const { status, headers } = error?.response;
                    if (status === 401 && headers?.get("www-authenticate")?.startsWith("Bearer ")) {
                        axiosHttp.interceptors.response.eject(responseInterceptor);
                        try {
                            const MAX_RETRIES = 2;
                            config._retries = Math.abs(config._retries) || 0;
                            if (config._retries >= MAX_RETRIES)
                                throw new Error(`Max retries(${config?._retries}) reached!`);
                            const { data } = await axiosHttp.post(API.user.reauth, {});
                            const newAccessToken = data?.accessToken;
                            config.headers.Authorization = `Bearer ${newAccessToken}`;
                            store.dispatch(setAccessToken({ accessToken: newAccessToken }));
                            config._retries++;
                            attachResponseInterceptor();
                            return axiosHttp({ ...config, headers: config.headers.toJSON() });
                        } catch (reauthError) {
                            attachResponseInterceptor();
                        }
                    }
                }
                logError(error, store);
                return Promise.reject(error);
            }
        );
    }
    attachResponseInterceptor();
}

const interceptors = { attach: runInterceptors };

export default interceptors;