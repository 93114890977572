import { useSlate } from "slate-react"
import { Button } from "../../elements"
import { TEXT_ALIGN_TYPES, isBlockActive, toggleBlock } from "../../helpers"

const BlockButton = ({ format, icon }: any) => {
    const editor = useSlate()
    return (
        <Button
            active={isBlockActive(
                editor,
                format,
                TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
            )}
            onMouseDown={(event: any) => {
                event.preventDefault()
                toggleBlock(editor, format)
            }}
        >
            {icon}
        </Button>
    )
}

export default BlockButton;