import { Helmet } from "react-helmet";
import { APP_NAME } from "../../utils/global";
import { textCapitalize } from "../../utils/styleModifier";
import Moment from 'moment';

interface Props {
    title: string | undefined;
    description?: string;
    route?: string;
    author?: string;
    date?: Date;
    isStructuredData?: boolean;
}

interface StructuredData {
    description: string;
    author: string;
    url: string;
    datePublished: string;
}

const META = {
    origin: process.env.REACT_APP_ORIGIN,
    description: 'The premier collaborative note-taking application. Seamlessly create, share, and collaborate on notes in real-time. Packed with features and optimized for speed. Empowering users to capture ideas and collaborate effectively. Join us to revolutionize note-taking.',
    author: 'Mayank Sharma',
    url: 'https://bakstak.com',
    datePublished: '2024-04-20'
}

const ldJson = (data: StructuredData) => {
    return {
        "@context": "https://schema.org",
        "@type": "SoftwareApplication",
        "name": "Bakstak",
        "description": data?.description,
        "url": data?.url,
        "author": {
            "@type": "Person",
            "name": data?.author
        },
        "datePublished": data?.datePublished,
        "applicationCategory": "Productivity",
        "operatingSystem": "Web"
    }
}

function PageMetaData({ title, route = '', description, author, date, isStructuredData = false }: Props) {
    return (
        <Helmet>
            <title>{`${textCapitalize(APP_NAME)} | ${title}`}</title>
            <meta property="og:title" content={`${textCapitalize(APP_NAME)} | ${title}`} />
            <meta property="og:url" content={`${META.origin}/${route}`} />
            <meta name="description" content={description || META.description} />
            <meta property="og:description" content={description || META.description} />
            {
                author &&
                <meta name="author" content={author} />
            }
            {
                isStructuredData &&
                <script type="application/ld+json">
                    {JSON.stringify(ldJson({
                        description: description || META.description,
                        author: author || META.author,
                        url: `${META.origin}/${route}` || META.url,
                        datePublished: date ? Moment(date).format('YYYY-MM-DD') : META.datePublished
                    }))}
                </script>
            }
        </Helmet>
    );
}

export default PageMetaData;
