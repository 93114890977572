import { Button, Divider, Form, Input } from "antd";
import { Controller, useForm } from "react-hook-form";
import { EMAIL_PATTERN } from "../../../utils/global";
import { useState } from "react";
import axiosHttp from "../../../axiosHttp";
import { API } from "../../../apis";
import { useDispatch } from "react-redux";
import { alertMessage } from "../../../store/reducers/alertMessageReducer";
import { gTracker } from "../../../utils/gTracker";
import { AuthAction } from "../AuthAction";

type FormData = {
    email: string;
}

function ForgotPassword({ changeAuthType }: any) {

    const [isLoading, setIsloading] = useState<boolean>(false);
    const { control, handleSubmit, formState: { errors } } = useForm<FormData>({
        defaultValues: {
            email: ''
        }
    });
    const dispatch = useDispatch();

    const onSubmit = (data: FormData) => {
        setIsloading(true);
        axiosHttp.post(API.user.forgot_password, data, {
            headers: {
                "X-reset-base": `${document.location.origin}/password-reset`
            }
        }).then((response) => {
                gTracker({ event_name: 'user_password_reset', screen_name: 'Password Reset' });
                dispatch(alertMessage({ type: 'success', content: 'New password link has been sent to the provided email address.' }));
            })
            .catch((error) => console.log(error))
            .finally(() => setIsloading(false));
    };

    const goToSignIn = () => {
        changeAuthType(AuthAction.SignIn);
    }

    return (
        <Form layout="vertical">
            <Form.Item label="Email" validateStatus={errors.email ? 'error' : ''}
                help={errors.email ? errors.email.message : null} required>
                <Controller
                    name="email"
                    control={control}
                    rules={{
                        required: 'Email is required', pattern: {
                            value: EMAIL_PATTERN,
                            message: "Enter valid email",
                        }
                    }}
                    render={({ field }) => (
                        <Input {...field} placeholder="Email" type="email" size="large" autoComplete="disabled" />
                    )}
                />
            </Form.Item>

            <Form.Item className="m-t-2">
                <Button style={{ width: '100%', marginBottom: '10px' }} type="primary" htmlType="submit" size="large" loading={isLoading} onClick={handleSubmit(onSubmit)}>
                    Submit
                </Button>

                <small className="text-light">
                    You will be sent an email with a password reset link to change your password.
                </small>
            </Form.Item>

            <Divider plain>
                <div className="text-accent-color cursor-pointer" onClick={goToSignIn}>Back To Sign In</div>
            </Divider>
        </Form>
    );
}

export default ForgotPassword;