import axios from "axios";

const axiosConfig = {
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: true,
    timeout: 20000,
    timeoutErrorMessage: 'Waiting for too long...Aborted !',
}

const axiosHttp = axios.create(axiosConfig);

export default axiosHttp;
