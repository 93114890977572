import { alertMessage } from "../store/reducers/alertMessageReducer";
import { removeUser } from "../store/reducers/userReducer";

function logError(error: any, store: any) {
    console.log('Error!!',  error)
    if (error.response) {
        const { error: respMessage, feedback: respFeedback } = error.response.data;
        const DEFAULTMSG = "Something's not right, Try again later.";
        let errorMessage = respFeedback || respMessage || DEFAULTMSG;
        if (error.response.status === 401) {
            // errorMessage = "You need to sign In";
            store?.dispatch(removeUser());
        }
        store?.dispatch(alertMessage({ type: 'error', content: errorMessage, duration: 5 }));
    }
    else if (error.request) {
        store?.dispatch(alertMessage({ type: 'error', content: error.message, duration: 5 }));
    }
    else {
        store?.dispatch(alertMessage({ type: 'error', content: 'Some error occured!', duration: 5 }));
    }
}

export default logError;