import { useEffect, useRef } from "react"
import { Editor, Range } from "slate"
import { useFocused, useSlate } from "slate-react"
import { Menu, Portal } from "../elements"
import FormatButton from "./Buttons/FormatButton"
import { BoldOutlined, ItalicOutlined, UnderlineOutlined } from "@ant-design/icons"

const HoveringToolbar = () => {
    const ref: any = useRef<HTMLDivElement | null>()
    const editor = useSlate()
    const inFocus = useFocused()

    useEffect(() => {
        const el = ref.current
        const { selection } = editor

        if (!el) {
            return
        }

        if (
            !selection ||
            !inFocus ||
            Range.isCollapsed(selection) ||
            Editor.string(editor, selection) === ''
        ) {
            el.removeAttribute('style')
            return
        }

        const domSelection: any = window.getSelection()
        const domRange = domSelection.getRangeAt(0)
        const rect = domRange.getBoundingClientRect()
        el.style.opacity = '1'
        el.style.top = `${rect.top + window.scrollY - el.offsetHeight}px`
        el.style.left = `${rect.left + window.scrollX - el.offsetWidth / 2 + rect.width / 2
            }px`
    })

    return (
        <Portal>
            <Menu
                ref={ref}
                className="hovering-toolbar"
                onMouseDown={(e: any) => {
                    e.preventDefault()
                }}>
                <FormatButton format="bold" icon={<BoldOutlined />} />
                <FormatButton format="italic" icon={<ItalicOutlined />} />
                <FormatButton format="underline" icon={<UnderlineOutlined />} />
            </Menu>
        </Portal>
    )
}

export default HoveringToolbar;