import { useCallback, useEffect, useMemo, useState } from "react";
import { Editable, Slate, withReact } from "slate-react";
import Element from "../components/Element";
import { Descendant, createEditor } from "slate";
import { Document, Note } from "../../../interface/Note";
import Moment from 'moment';
import Leaf from "../components/Leaf";
import { withImages, withInlines } from "../modifiers";

interface Props {
    note: Note;
    document: Document;
}

function NotePostEditor({ note, document }: Props) {

    const renderElement = useCallback((props: any) => <Element {...props} />, []);
    const renderLeaf = useCallback((props: any) => <Leaf {...props} />, []);
    const editor = useMemo(() => withImages(withInlines(withReact(createEditor()))), []);
    const [slateValue, setSlateValue] = useState<Descendant[]>();

    useEffect(() => {
        if (document) {
            const userSlateNodes = [
                { type: 'paragraph', children: [{ text: "" }] },
                { type: 'author', children: [{ text: note.owner.name }] },
                { type: 'date', children: [{ text: Moment(note.updatedOn).format('MMMM DD, YYYY') }] },
                { type: 'paragraph', children: [{ text: "" }] }
            ];
            setSlateValue([...document.document, ...userSlateNodes]);
        }
    }, [document]);

    return (
        slateValue &&
        <div className='slate-editor'>
            <Slate editor={editor} initialValue={slateValue}>
                <Editable
                    className='text-editor-readonly'
                    name='post'
                    aria-label='Post'
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    readOnly />
            </Slate>
        </div>
    )
}

export default NotePostEditor;