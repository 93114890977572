import { Fragment, useEffect, useState } from "react";
import "./Notes.scss";
import axiosHttp from "../../axiosHttp";
import { API } from "../../apis";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../store/reducers/loaderReducer";
import { Stack } from "../../interface/Stack";
import { Note } from "../../interface/Note";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import NoteCard from "./components/NoteCard";
import StackHeader from "./components/StackHeader";
import PageMetaData from "../../components/PageMetaData";
import { Empty } from "antd";
import { useSelector } from "react-redux";
import NoteSkeleton from "./components/NoteSkeleton";

function Notes() {

    const { stackLinkID } = useParams();
    const dispatch = useDispatch();
    const [stack, setStack] = useState<Stack | null>(null);
    const [notes, setNotes] = useState<Note[]>([]);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const isLoading = useSelector((state: any) => state.loader.loading);


    useEffect(() => {
        if (stackLinkID) {
            dispatch(startLoading());
            axiosHttp.get(API.stack.stack_notes(stackLinkID))
                .then(response => {
                    if (response.data.success) {
                        const { stack, notes } = response.data;
                        setStack(stack);
                        setNotes(notes);
                    }
                })
                .catch(error => console.log(error))
                .finally(() => {
                    setIsLoaded(true);
                    dispatch(stopLoading());
                });
        }
    }, [stackLinkID]);

    return (
        <Fragment>
            <PageMetaData
                route={`stack/${stackLinkID}`}
                title={stack?.name || 'Stack'}
                description={stack?.description || ''}
                author={stack?.owner?.name}
                date={stack?.updatedOn}
                isStructuredData={true} />

            <div className="page-container">
                {
                    isLoading && !stack &&
                    <NoteSkeleton />
                }

                {
                    stack && isLoaded &&
                    <div className="stack-header">
                        <StackHeader stack={stack} />
                    </div>
                }
                <div className="contain">
                    <div className="public-notes">
                        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 710: 2, 1090: 3 }}>
                            <Masonry gutter="14px">
                                {
                                    notes.map((note: Note, index: number) => (
                                        <NoteCard key={index} note={note} />
                                    ))
                                }
                            </Masonry>
                        </ResponsiveMasonry>
                        {
                            isLoaded && (
                                stack ?
                                    !notes.length && <Empty className="m-t-3" image={Empty.PRESENTED_IMAGE_SIMPLE} description="This stack is empty!" /> :
                                    <Empty className="m-t-4" image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Stack Here!" />
                            )
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Notes;