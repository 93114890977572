import { Space } from "antd";
import { Stack } from "../../../interface/Stack";

interface Props {
    stack: Stack | null
}

function StackHeader({ stack }: Props) {
    return (
        <Space direction="vertical">
            <div className="stack-name">{stack?.name}</div>
            {stack?.description && <div className="stack-description">{stack?.description}</div>}
        </Space>
    )
}

export default StackHeader;