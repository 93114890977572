import { Button, Divider, Form, Input } from "antd";
import { Controller, useForm } from "react-hook-form";
import { EMAIL_PATTERN } from "../../../utils/global";
import { API } from "../../../apis";
import { useDispatch } from "react-redux";
import { saveUser } from "../../../store/reducers/userReducer";
import axiosHttp from "../../../axiosHttp";
import { useState } from "react";
import { alertMessage } from "../../../store/reducers/alertMessageReducer";
import { gTracker } from "../../../utils/gTracker";
import { AuthAction } from "../AuthAction";

type FormData = {
    name: string;
    email: string;
    password: string;
    confirmPassword: string;
}

function SignUp({ changeAuthType, closeAuth }: any) {

    const [isLoading, setIsloading] = useState<boolean>(false);
    const dispatch = useDispatch();
    const { control, handleSubmit, watch, formState: { errors } } = useForm<FormData>({
        defaultValues: {
            name: '',
            email: '',
            password: '',
            confirmPassword: ''
        }
    });
    const password = watch('password');

    const onSubmit = (data: FormData) => {
        setIsloading(true);
        axiosHttp.post(API.user.sign_up, data, {
            headers: {
                "X-verify-email": `${document.location.origin}/verify-email`
            }
        }).then((response) => {
            gTracker({ event_name: 'user_sign_up', screen_name: 'Sign-Up' });
            if (!response.data.emailSent) {
                gTracker({ event_name: 'sign_up_email_failed', screen_name: 'Sign-Up' });
            }
            dispatch(saveUser(response.data));
            dispatch(alertMessage({ type: 'success', content: "Welcome to Bakstak! Thank you for joining us. Please verify your account by clicking the link in the email we've sent to you.", duration: 10 }));
            closeAuth();
        }).catch((err) => console.log(err)).finally(() => setIsloading(false));
    };

    const goToSignIn = () => {
        changeAuthType(AuthAction.SignIn);
    }

    return (
        <Form layout="vertical">
            <Form.Item label="Name" validateStatus={errors.name ? 'error' : ''}
                help={errors.name ? errors.name.message : null} required>
                <Controller
                    name="name"
                    control={control}
                    rules={{ required: 'Name is required' }}
                    render={({ field }) => (
                        <Input {...field} placeholder="Name" autoComplete="disabled" size="large" />
                    )}
                />
            </Form.Item>
            <Form.Item label="Email" validateStatus={errors.email ? 'error' : ''}
                help={errors.email ? errors.email.message : null} required>
                <Controller
                    name="email"
                    control={control}
                    rules={{
                        required: 'Email is required', pattern: {
                            value: EMAIL_PATTERN,
                            message: "Enter valid email"
                        }
                    }}
                    render={({ field }) => (
                        <Input {...field} placeholder="Email" type="email" autoComplete="disabled" size="large" />
                    )}
                />
            </Form.Item>
            <Form.Item label="Password" className="m-t-1" validateStatus={errors.password ? 'error' : ''}
                help={errors.password ? errors.password.message : null} required>
                <Controller
                    name="password"
                    control={control}
                    rules={{
                        required: 'Password is required',
                        minLength: {
                            value: 6,
                            message: 'Password should have minimum 6 characters'
                        }
                    }}
                    render={({ field }) => (
                        <Input.Password {...field} placeholder="Password" type="password" autoComplete="new-password" size="large" />
                    )}
                />
            </Form.Item>

            <Form.Item label="Confirm Password" className="m-t-1" validateStatus={errors.confirmPassword ? 'error' : ''}
                help={errors.confirmPassword ? errors.confirmPassword.message : null} required>
                <Controller
                    name="confirmPassword"
                    control={control}
                    rules={{
                        required: 'Confirm Password is required',
                        validate: value =>
                            value === password || "The passwords do not match"
                    }}
                    render={({ field }) => (
                        <Input.Password {...field} placeholder="Confirm Password" type="password" autoComplete="new-password" size="large" />
                    )}
                />
            </Form.Item>

            <Form.Item className="m-t-2">
                <Button style={{ width: '100%' }} type="primary" htmlType="submit" loading={isLoading} size="large" onClick={handleSubmit(onSubmit)}>
                    Sign Up
                </Button>
            </Form.Item>

            <Divider plain>
                <div className="text-accent-color cursor-pointer" onClick={goToSignIn}>Back To Sign In</div>
            </Divider>
        </Form>
    );
}

export default SignUp;