import { Button, Card, Flex, Form, Input, Modal, Select, Space, Switch } from "antd";
import { Fragment, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Note } from "../../../interface/Note";
import axiosHttp from "../../../axiosHttp";
import { API } from "../../../apis";
import { useDispatch } from "react-redux";
import { updateNote } from "../../../store/reducers/noteReducer";
import { LockOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { alertMessage } from "../../../store/reducers/alertMessageReducer";
import { urlSafeModifier } from "../../../utils/urlSafeModifier";
import { UserState } from "../../../store/reducers/userReducer";

type FormData = {
    isEditable: boolean,
    isProtected: boolean,
    password?: string,
    isSEO: boolean,
    noteLinkID: string,
    metaTitle: string | undefined,
    metaDescription: string | undefined,
    stack?: string
}

interface StackOptions {
    label: string;
    value: string;
}

function NoteSettingsModal({ isSettingsModalOpen, deviceType, isLoggedIn, setIsModalClose }: any) {

    const [isLoading, setIsloading] = useState<boolean>(false);
    const [isValidating, setisValidating] = useState<boolean>(false);
    const [stackOptions, setStackOptions] = useState<StackOptions[]>([]);
    const { control, handleSubmit, watch, setValue, formState: { errors } } = useForm<FormData>({
        defaultValues: {
            isEditable: true,
            isProtected: false,
            password: '',
            isSEO: false,
            noteLinkID: '',
            metaTitle: '',
            metaDescription: '',
            stack: ''
        }
    });
    const note: Note = useSelector((state: any) => state.note.data);
    const user: UserState = useSelector((state: any) => state.user);
    const dispatch = useDispatch();
    const [isNoteLocked, setIsNoteLocked] = useState<boolean>(false);
    const location = useLocation();
    const navigate = useNavigate();
    const isEditable = watch('isEditable', true);
    const isProtected = watch('isProtected', false);
    const password = watch('password');
    const isSEO = watch('isSEO', false);

    const onSubmit = (data: FormData) => {
        if (!note) {
            dispatch(alertMessage({ type: 'warning', content: 'Note is empty!' }));
            return;
        }
        setIsloading(true);
        if (!data.isProtected) {
            data.password = ''
        }
        if (!data.isSEO) {
            data.metaTitle = '';
            data.metaDescription = '';
        }
        if (!data.stack) {
            delete data.stack;
        }
        const { noteLinkID, ...formData } = data;
        const currentNoteLinkID = location.pathname.split('/').pop();
        const newNoteLinkID = urlSafeModifier(noteLinkID);
        const dataToSend: any = {
            ...formData,
            currentNoteLinkID: currentNoteLinkID,
            newNoteLinkID: newNoteLinkID
        }
        axiosHttp.put(API.note.operate_by_id(note._id), dataToSend)
            .then((response) => {
                const { note: noteResponse } = response.data;
                if (currentNoteLinkID !== noteResponse.noteLinkID) {
                    navigate(`/${noteResponse.noteLinkID}`, { replace: true });
                }
                dispatch(updateNote(noteResponse));
                dispatch(alertMessage({ type: 'success', content: 'Note settings updated successfully!' }));
                setIsModalClose();
            })
            .catch((err) => console.log(err))
            .finally(() => setIsloading(false));
    };

    const handleCancel = () => {
        setIsModalClose();
    };

    const validatePassword = () => {
        if (!password) {
            dispatch(alertMessage({ type: 'error', content: 'Please enter your password' }));
            return;
        }
        setisValidating(true);
        axiosHttp.put(API.note.validate(note._id), { password: password })
            .then((response) => {
                if (response.data.validated) {
                    dispatch(alertMessage({ type: 'success', content: 'Note validated successfully!' }));
                    setIsNoteLocked(false);
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setisValidating(false);
            });
    }

    const passwordValidation = () => {
        return (
            <Form.Item label="Password" className="m-t-1 m-b-1" validateStatus={errors.password ? 'error' : ''}
                help={errors.password ? errors.password.message : null}
                extra={<small style={{ paddingLeft: '2px' }}>{isProtected ? 'Enter password to validate, if you wish to unlock or update this note' : 'Use this password to lock this note'}</small>} required>
                <Controller
                    name="password"
                    control={control}
                    rules={{
                        required: 'Password is required',
                        minLength: {
                            value: 6,
                            message: 'Password should have minimum 6 characters'
                        }
                    }}
                    render={({ field }) => (
                        <Space.Compact style={{ width: '100%' }}>
                            <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} {...field} placeholder="Password" type="password" autoComplete="new-password" />
                            {isNoteLocked && <Button type="primary" loading={isValidating} onClick={validatePassword}>Validate</Button>}
                        </Space.Compact>
                    )}
                />
            </Form.Item>
        )
    }

    const seoForm = () => {
        return (
            <Fragment>
                <Form.Item label="Title" className="m-t-1 m-b-1" validateStatus={errors.metaTitle ? 'error' : ''}
                    help={errors.metaTitle ? errors.metaTitle.message : null} required>
                    <Controller
                        name="metaTitle"
                        control={control}
                        rules={{
                            required: 'Title is required',
                            maxLength: {
                                value: 50,
                                message: 'Title cannot be more than 50 characters long.'
                            }
                        }}
                        render={({ field }) => (
                            <Input {...field} placeholder="Title" />
                        )}
                    />
                </Form.Item>

                <Form.Item label="Description" className="m-t-1 m-b-1" validateStatus={errors.metaDescription ? 'error' : ''}
                    help={errors.metaDescription ? errors.metaDescription.message : null} required>
                    <Controller
                        name="metaDescription"
                        control={control}
                        rules={{
                            required: 'Description is required'
                        }}
                        render={({ field }) => (
                            <Input.TextArea {...field} placeholder="Description" rows={3} />
                        )}
                    />
                </Form.Item>
            </Fragment>
        )
    }

    useEffect(() => {
        if (note) {
            setValue('isEditable', note?.isEditable);
            setValue('isProtected', note?.isProtected);
            setValue('isSEO', note?.isSEO);
            setValue('metaTitle', note?.metaTitle);
            setValue('metaDescription', note?.metaDescription);
            setValue('stack', note?.stack?._id || '');
            setIsNoteLocked(true);
        }
        setValue('noteLinkID', note?.noteLinkID || location.pathname.split('/').pop()!);
    }, [note]);

    useEffect(() => {
        setIsNoteLocked(note?.isProtected);
    }, [note?.isProtected]);

    useEffect(() => {
        setValue('isEditable', isSEO ? false : note?.isEditable);
    }, [isSEO]);

    useEffect(() => {
        if (user?.isLoggedIn && user?.accessToken) {
            axiosHttp.get(API.stack.note_options)
                .then(response => {
                    setStackOptions(response.data.stacks);
                })
                .catch(error => console.log(error));
        }
    }, [user]);

    return (

        <Modal title="Note Settings" width={deviceType === 'mobile' ? '100%' : 520}
            open={isSettingsModalOpen}
            onCancel={handleCancel}
            footer={[
                <Button
                    className="m-t-1"
                    key="submit"
                    type="primary"
                    style={{ width: deviceType === 'mobile' ? '100%' : '150px' }}
                    disabled={!isLoggedIn || isValidating || !note} loading={isLoading}
                    onClick={handleSubmit(onSubmit)}>
                    Update
                </Button>]}>

            <Form layout="vertical" className="m-t-1">
                {!isLoggedIn && <div className="text-light m-b-1 m-t-1">Sign In, if you wish to update note settings!</div>}
                {isLoggedIn && !note && <div className={`text-light m-b-1 m-t-1 ${deviceType === 'mobile' ? 'font-size-11' : ''}`}>
                    This notepad is ready for your thoughts! Once you've jotted something down, you can update your note settings.
                </div>}
                <Form.Item className="m-b-1">
                    <Controller
                        name="isEditable"
                        control={control}
                        render={({ field }) => (
                            <Card title="Accessibility" size="small" extra={<small className="font-weight-700">{isEditable ? 'Editable' : 'Ready-Only'}</small>}>
                                <Flex justify="space-between">
                                    <small className="text-light" style={{ width: '75%' }}>Toggle to switch between making the note editable or read-only.</small>
                                    <Switch {...field} disabled={!isLoggedIn || isSEO} />
                                </Flex>
                            </Card>
                        )}
                    />
                </Form.Item>

                <Form.Item className="m-b-1">
                    <Controller
                        name="isProtected"
                        control={control}
                        render={({ field }) => (
                            <Card title="Security" size="small" extra={<small className="font-weight-700">{isProtected ? 'Locked' : 'Unlocked'}</small>}>
                                <Flex justify="space-between">
                                    <small className="text-light" style={{ width: '75%' }}>Toggle to switch between locking and unlocking the note.</small>
                                    <Switch {...field} disabled={!isLoggedIn || isNoteLocked || isSEO} />
                                </Flex>

                                {isProtected && passwordValidation()}
                            </Card>
                        )}
                    />
                </Form.Item>

                <Form.Item className="m-b-1">
                    <Controller
                        name="isSEO"
                        control={control}
                        render={({ field }) => (
                            <Card title="Publish" size="small" extra={<small className="font-weight-700">{isSEO ? 'Yes' : 'No'}</small>}>
                                <Flex justify="space-between">
                                    <Space direction="vertical" style={{ width: '75%' }} size={0}>
                                        <small className="text-light">Toggle to publish your note as a blog post. Search engines will be able to crawl through this page.</small>
                                        {isProtected && <small>**Unlock the note if you wish to publish.</small>}
                                    </Space>
                                    <Switch {...field} disabled={!isLoggedIn || isProtected} />
                                </Flex>

                                {isSEO && seoForm()}
                            </Card>
                        )}
                    />
                </Form.Item>

                <Card title="Note Link" size="small" className="update-note-link m-b-1">
                    <Form.Item label="Link" tooltip={<small>You can update current note link here</small>} className="m-b-0" validateStatus={errors.noteLinkID ? 'error' : ''}
                        extra={isSEO && <small>Note will be published on this link, you can update the link if you wish.</small>}
                        help={errors.noteLinkID ? errors.noteLinkID.message : null}>
                        <Controller
                            name="noteLinkID"
                            control={control}
                            rules={{
                                required: 'Link is required'
                            }}
                            render={({ field }) => (
                                <Input {...field} style={{ opacity: '0.8' }} placeholder="Link" disabled={!isLoggedIn} />
                            )}
                        />
                    </Form.Item>
                </Card>

                <Card title="Stack" size="small" className="update-note-link">
                    <Form.Item label="Select" className="m-b-0"
                        tooltip={<small>You can add this note to a stack.</small>}
                        extra={!stackOptions.length && <small>{`${user?.isLoggedIn ? 'You have not created any stacks yet' : 'Sign-In to create stacks'}`}.</small>}>
                        <Controller
                            name="stack"
                            disabled={!stackOptions.length}
                            control={control}
                            render={({ field }) => (
                                <Select
                                    showSearch
                                    filterOption={filterStackOption}
                                    {...field}
                                    defaultValue=""
                                    style={{ width: '100%', opacity: '0.8' }}
                                    options={[
                                        { value: '', label: 'None' },
                                        ...stackOptions
                                    ]}
                                />
                            )}
                        />
                    </Form.Item>
                </Card>
            </Form>

        </Modal>
    );
}

const filterStackOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

export default NoteSettingsModal;