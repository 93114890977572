import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = () => {

    const isLoggedIn: boolean = useSelector((state: any) => state.user.isLoggedIn);
    const here = useLocation();

    if (!isLoggedIn) {
        return (
            <Navigate
                replace={true}
                state={{ comingFrom: here, reason: "NOAUTH" }}
                to="/"
            />
        )
    }
    return <Outlet />;
}

export default PrivateRoute;