import { Button, Card, Flex } from 'antd';
import { Link } from 'react-router-dom';
import PageMetaData from '../../components/PageMetaData';
import { Fragment } from 'react/jsx-runtime';

function NotFound() {
    return (
        <Fragment>
            <PageMetaData title="404" />
            <Flex style={{ height: '100%' }} align='center' justify='center'>
                <Card>
                    <Flex align='center' justify='center' gap={'small'} vertical>
                        <h1 className='m-0'>404</h1>
                        <h3 className='text-opacity-7 m-0'>Page Not Found</h3>
                        <div className='text-opacity-7'>Sorry, the page you visited does not exist.</div>
                        <Link to="/">
                            <Button className='m-t-1' type="primary">Back Home</Button>
                        </Link>
                    </Flex>
                </Card>
            </Flex>
        </Fragment>
    );
}

export default NotFound;
