import { Checkbox } from "antd"
import {
    Element as SlateElement,
    Transforms
} from "slate"
import { ReactEditor, useReadOnly, useSlateStatic } from "slate-react"

const CheckListItemElement = ({ attributes, children, element, style }: any) => {
    const editor: any = useSlateStatic()
    const readOnly = useReadOnly()
    const { checked } = element
    return (
        <div
            style={style}
            {...attributes}
            className="checklist-element">
            <span
                contentEditable={false}
                className="checkbox">
                <Checkbox
                    checked={checked}
                    onChange={event => {
                        const path = ReactEditor.findPath(editor, element)
                        const newProperties: Partial<SlateElement> | any = {
                            checked: event.target.checked,
                        }
                        Transforms.setNodes(editor, newProperties, { at: path })
                    }} />
            </span>
            <span
                contentEditable={!readOnly}
                suppressContentEditableWarning
                className={`check-text ${checked ? 'checked' : ''}`}>
                {children}
            </span>
        </div>
    )
}

export default CheckListItemElement;