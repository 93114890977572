import { createSlice, PayloadAction } from '@reduxjs/toolkit';

enum DeviceType {
    MOBILE = 'mobile',
    DESKTOP = 'desktop',
    TABLET = 'tablet',
}

interface DeviceTypeState {
    type: DeviceType;
}

const initialState: DeviceTypeState = {
    type: getDeviceType(window.innerWidth),
};

const deviceTypeSlice = createSlice({
    name: 'deviceType',
    initialState,
    reducers: {
        setDeviceType: (state, action: PayloadAction<number>) => {
            state.type = getDeviceType(action.payload);
        },
    },
});

export const { setDeviceType } = deviceTypeSlice.actions;

export default deviceTypeSlice.reducer;

function getDeviceType(width: number): DeviceType {
    if (width <= 481) {
        return DeviceType.MOBILE;
    } else if (width <= 1024) {
        return DeviceType.TABLET;
    } else {
        return DeviceType.DESKTOP;
    }
}
