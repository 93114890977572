import { Navigate, createBrowserRouter } from "react-router-dom";
import { Suspense, lazy } from "react";
import Home from "../containers/Home";
import NotFound from "../containers/NotFound";
import PrivateRoute from "../HOC/PrivateRoute";
import Loading from "../components/Loading";
import Layout from "../containers/Layout";
import Notes from "../containers/Notes";
import EditorRoute from "../HOC/EditorRoute";

const UserNotes = lazy(() => import('../containers/UserNotes'));
const Stacks = lazy(() => import('../containers/Stacks'));
const TermsAndConditions = lazy(() => import('../containers/TermsAndConditions'));
const PrivacyPolicy = lazy(() => import('../containers/PrivacyPolicy'));
const ContactUs = lazy(() => import('../containers/ContactUs'));
const Profile = lazy(() => import('../containers/Profile'));
const About = lazy(() => import('../containers/About'));
const ResetPassword = lazy(() => import('../containers/ResetPassword'));
const EmailVerification = lazy(() => import('../containers/EmailVerification'));

const router = createBrowserRouter([
    {
        path: "",
        element: <Layout />,
        children: [
            { path: "stack/:stackLinkID", element: <Notes /> },
            {
                path: "",
                element: <EditorRoute />,
                children: [
                    { path: "", element: <Home /> },
                    { path: ":noteLinkID", element: <Home /> }
                ]
            },
            {
                path: "",
                element: <PrivateRoute />,
                children: [
                    { path: "notes", element: <Suspense fallback={<Loading />}><UserNotes /></Suspense> },
                    { path: "stacks", element: <Suspense fallback={<Loading />}><Stacks /></Suspense> },
                    { path: "stacks/:stackId/notes", element: <Suspense fallback={<Loading />}><UserNotes /></Suspense> },
                    { path: "profile", element: <Suspense fallback={<Loading />}><Profile /></Suspense> }
                ]
            },
            { path: "password-reset/:resetToken", element: <Suspense fallback={<Loading />}><ResetPassword /></Suspense> },
            { path: "verify-email/:verificationToken", element: <Suspense fallback={<Loading />}><EmailVerification /></Suspense> },
            { path: "about-us", element: <Suspense fallback={<Loading />}><About /></Suspense> },
            { path: "contact-us", element: <Suspense fallback={<Loading />}><ContactUs /></Suspense> },
            { path: "privacy-policy", element: <Suspense fallback={<Loading />}><PrivacyPolicy /></Suspense> },
            { path: "terms-and-conditions", element: <Suspense fallback={<Loading />}><TermsAndConditions /></Suspense> },
            { path: "404", element: <NotFound /> },
            {
                path: "*",
                element: <Navigate to="/404" />,
            }
        ]
    }
]);

export { router };