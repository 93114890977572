import { useCallback, useEffect, useMemo, useState } from 'react';
import { Editable, withReact, Slate } from 'slate-react';
import {
    Editor,
    Transforms,
    createEditor,
    Descendant,
    Range
} from 'slate';
import isHotkey, { isKeyHotkey } from 'is-hotkey';
import { EditOutlined, ReadOutlined } from '@ant-design/icons';
import "./TextEditor.scss";
import { withYjs, YjsEditor, withYHistory } from '@slate-yjs/core';
import * as Y from 'yjs';
import { HocuspocusProvider } from '@hocuspocus/provider';
import { Note } from '../../interface/Note';
import { FloatButton } from 'antd';
import { useDispatch } from 'react-redux';
import { updateNote } from '../../store/reducers/noteReducer';
import { User } from '../../interface/User';
import HoveringToolbar from './components/HoveringToolbar';
import { toggleMark } from './helpers';
import Leaf from './components/Leaf';
import Element from './components/Element';
import EditorToolbar from './components/EditorToolbar';
import { withChecklists, withHtml, withImages, withInlines, withLayout, withTables } from './modifiers';

const HOTKEYS: any = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
    'mod+`': 'code',
};

interface propData {
    noteLinkID: string | undefined;
    noteData: Note | null;
    userData: User | null;
}

function TextEditor({ noteLinkID, noteData, userData }: propData) {

    const [editorValue, setEditorValue] = useState<Descendant[]>([]);
    const renderElement = useCallback((props: any) => <Element {...props} />, []);
    const renderLeaf = useCallback((props: any) => <Leaf {...props} />, []);
    const [isEditable, setIsEditable] = useState<boolean>(false);
    const [isQuickEdit, setIsQuickEdit] = useState<boolean>(false);
    const [connected, setConnected] = useState<boolean>(false);
    const dispatch = useDispatch();

    const provider = useMemo(
        () => {
            const providerOptions = {
                url: `${process.env.REACT_APP_HOCUSPOCUS_BASE_URL}/${noteLinkID}`,
                name: noteLinkID!,
                connect: false,
                preserveConnection: false,
                parameters: userData && userData?._id ? { userId: userData._id } : {},
                onStateless: ({ payload }: any) => {
                    const note = JSON.parse(payload);
                    dispatch(updateNote(note));
                },
                onStatus: ({ status }: any) => {
                    setConnected(status === 'connected');
                }
            }
            return new HocuspocusProvider(providerOptions);
        }, []
    );

    const editor = useMemo(() => {
        const sharedType = provider.document.get('content', Y.XmlText);
        const e = withHtml(withLayout(withInlines(withImages(withTables(withChecklists(withReact(withYHistory(withYjs(createEditor(), sharedType)))))))));
        const { normalizeNode } = e;
        e.normalizeNode = (entry: any) => {
            const [node] = entry;
            if (!Editor.isEditor(node) || node.children.length > 0) {
                return normalizeNode(entry);
            }
        };
        return e;
    }, [provider.document]);

    const onKeyDown = (event: any) => {
        const { selection } = editor;

        if (selection && Range.isCollapsed(selection)) {
            const { nativeEvent } = event;
            if (isKeyHotkey('left', nativeEvent)) {
                event.preventDefault()
                Transforms.move(editor, { unit: 'offset', reverse: true })
                return;
            }
            if (isKeyHotkey('right', nativeEvent)) {
                event.preventDefault()
                Transforms.move(editor, { unit: 'offset' })
                return;
            }
        }

        if (isHotkey('tab', event)) {
            event.preventDefault()
            Editor.insertText(editor, '  ')
        } else {
            for (const hotkey in HOTKEYS) {
                if (isHotkey(hotkey, event as any)) {
                    event.preventDefault()
                    const mark = HOTKEYS[hotkey]
                    toggleMark(editor, mark)
                }
            }
        }
    }

    const handleNoteChange = (value: Descendant[]) => {
        const isAstChange = editor.operations.some(
            (op: any) => 'set_selection' !== op.type
        )
        if (isAstChange) {
            setEditorValue(value);
        }
    };

    const setQuickEdit = () => {
        setIsQuickEdit(!isQuickEdit)
    }

    const quickEdit = () => {
        if (noteData && !noteData.isEditable && userData?._id === noteData?.owner?._id) {
            return (
                <FloatButton shape='square' tooltip={isQuickEdit ? 'Read-Only Mode' : 'Editing Mode'} icon={isQuickEdit ? <ReadOutlined /> : < EditOutlined />} onClick={setQuickEdit} />
            );
        }
    }

    useEffect(() => {
        provider.connect();
        return () => provider.disconnect();
    }, [provider]);

    useEffect(() => {
        YjsEditor.connect(editor);
        return () => YjsEditor.disconnect(editor);
    }, [editor]);

    useEffect(() => {
        setIsEditable(!noteData || (noteData?.isEditable ?? false));
    }, [noteData]);

    return (
        <div className='slate-editor'>
            <Slate editor={editor}
                initialValue={editorValue}
                onChange={handleNoteChange}>
                {(isEditable || isQuickEdit) && <EditorToolbar />}
                <HoveringToolbar />
                {
                    connected &&
                    <Editable
                        name='notepad'
                        aria-label='Note'
                        readOnly={!isEditable && !isQuickEdit}
                        renderElement={renderElement}
                        renderLeaf={renderLeaf}
                        spellCheck
                        className={isEditable || isQuickEdit ? 'text-editor' : 'text-editor-readonly'}
                        placeholder="Note"
                        renderPlaceholder={customPlaceholder}
                        onDOMBeforeInput={(event: InputEvent) => {
                            switch (event.inputType) {
                                case 'formatBold':
                                    event.preventDefault()
                                    return toggleMark(editor, 'bold')
                                case 'formatItalic':
                                    event.preventDefault()
                                    return toggleMark(editor, 'italic')
                                case 'formatUnderline':
                                    event.preventDefault()
                                    return toggleMark(editor, 'underlined')
                            }
                        }}
                        onKeyDown={onKeyDown}
                    />
                }
            </Slate>

            {quickEdit()}
        </div>
    );
}

const customPlaceholder = ({ children, attributes }: any) => {
    attributes.style = { ...attributes.style, marginTop: '15px' };
    return (
        <span {...attributes}>
            {children}
        </span>
    )
}

export default TextEditor;
