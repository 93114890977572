import { v4 as uuidv4 } from 'uuid';
import base64url from "base64url";

export function uniqueIdGenerator(): string {
    const hexString = uuidv4();
    const hexStringUndecorated = hexString.replace(/-/g, "");
    const base64String = base64url.fromBase64(hexStringUndecorated);
    return base64String;
}

export function shortUniqueId(): string {
    const hexString = uuidv4();
    const truncatedUuid = hexString.replace(/-/g, '').substring(2, 12);
    const base64String = base64url.fromBase64(truncatedUuid);
    return base64String;
}