import { Drawer, Flex } from "antd";
import { useState } from "react";
import Title from "../Title";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import ForgotPassword from "./ForgotPassword";
import Logo from "../Logo";
import { AuthAction } from "./AuthAction";

const AuthTitle = {
    [AuthAction.SignIn]: 'Sign-In',
    [AuthAction.SignUp]: 'Sign-Up',
    [AuthAction.ForgotPassword]: 'Forgot Password'
}

function Auth({ isAuthOpen, deviceType, setIsAuthOpen }: any) {

    const [authType, setAuthType] = useState<AuthAction>(AuthAction.SignIn);

    const onClose = () => {
        setAuthType(AuthAction.SignIn);
        setIsAuthOpen(false);
    };

    const changeAuthType = (authType: AuthAction) => {
        setAuthType(authType);
    }

    const renderAuthType = () => {
        switch (authType) {
            case AuthAction.SignIn:
                return <SignIn changeAuthType={changeAuthType} closeAuth={onClose} />
            case AuthAction.SignUp:
                return <SignUp changeAuthType={changeAuthType} closeAuth={onClose} />
            case AuthAction.ForgotPassword:
                return <ForgotPassword changeAuthType={changeAuthType} closeAuth={onClose} />
            default:
                return <SignIn />
        }
    }

    return (
        <Drawer
            title={AuthTitle[authType]}
            placement="right"
            width={520}
            onClose={onClose}
            open={isAuthOpen}>

            <div className={deviceType === 'mobile' ? 'm-t-1' : 'm-t-2'}>
                <Flex vertical>
                    <Flex justify="center">
                        <Logo height={deviceType === 'mobile' ? 100 : 118} width={deviceType === 'mobile' ? 100 : 118} size="medium" cursor={false} />
                    </Flex>
                    <Flex justify="center" style={{ paddingLeft: '4px', marginTop: '8px' }}>
                        <Title cursor={false} size={deviceType === 'mobile' ? 18 : 22} />
                    </Flex>
                    <div className={deviceType === 'mobile' ? 'p-l-2 p-r-2' : 'm-t-1 p-l-2 p-r-2'}>
                        {renderAuthType()}
                    </div>
                </Flex>
            </div>
        </Drawer>
    );
}

export default Auth;