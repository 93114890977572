import './Title.scss'
import { APP_NAME } from '../../utils/global';
import { Link } from 'react-router-dom';

interface Props {
    cursor?: boolean;
    size?: number;
}

const appName = APP_NAME;

function Title ({ cursor = true, size = 20 }: Props) {

    const renderTitle = () => {
        return (
            <h1 className="title" style={{ fontSize: size }}>
                {appName}
            </h1>
        )
    }

    return (
        cursor ? (
            <Link to="/" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                {renderTitle()}
            </Link>
        ) :
            renderTitle()
    );
}

export default Title;