import React, { useEffect } from 'react';
import { ConfigProvider, theme } from "antd";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setDeviceType } from './store/reducers/deviceTypeReducer';
import { accentColor } from './utils/styleModifier';
import AlertMessage from './components/AlertMessage';
import { RouterProvider } from 'react-router-dom';
import { router } from './routes/AppRoutes';

function App() {

  const { defaultAlgorithm, darkAlgorithm } = theme;
  const isDarkMode = useSelector((state: any) => state.theme.darkMode);
  const dispatch = useDispatch();

  useEffect(() => {
    document.documentElement.setAttribute(
      "data-theme",
      isDarkMode ? "dark" : "light"
    );
  }, [isDarkMode]);

  const handleWindowSizeChange = () => {
    dispatch(setDeviceType(window.innerWidth));
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  return (
    <ConfigProvider
      theme={{
        algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
        token: {
          colorPrimary: accentColor()
        },
        components: {
          Card: {
            headerFontSize: 20
          }
        },
      }}>
      <AlertMessage />
      <RouterProvider router={router} />
    </ConfigProvider>
  );
}

export default App;
