import { Dropdown, MenuProps } from "antd";
import { useMemo, useState } from "react";
import { useSlate } from "slate-react";
import { Button } from "../../elements";
import { toggleBlock } from "../../helpers";

const menuFormatItems: MenuProps['items'] = [
    {
        label: 'Title',
        key: 'title'
    },
    {
        label: 'Heading',
        key: 'heading-three'
    },
    {
        label: 'Sub Heading',
        key: 'heading-four'
    },
    {
        label: 'Body',
        key: 'paragraph'
    }
];

const DropButton = ({ icon }: any) => {
    const editor = useSlate()
    const [selectedFormat, selSelectedFormat] = useState<string>('');
    const handleMenuClick: MenuProps['onClick'] = (e) => {
        toggleBlock(editor, e.key)
    };
    const openDropdown = (isOpen: boolean) => {
        if (isOpen) {
            const { selection } = editor;
            if (!selection) return ''
            if (selection !== null && selection.anchor !== null) {
                const selected: any = editor.children[selection.anchor.path[0]];
                selSelectedFormat(selected.type)
            }
        }
    }
    const menuProps = useMemo(() => ({
        items: menuFormatItems,
        selectable: true,
        selectedKeys: [selectedFormat],
        defaultSelectedKeys: ['title'],
        onClick: handleMenuClick
    }), [selectedFormat]);
    return (
        <Dropdown menu={menuProps} trigger={['click']} placement="bottom" onOpenChange={openDropdown} arrow>
            <Button>
                {icon}
            </Button>
        </Dropdown>
    )
}

export default DropButton;