import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { User } from '../../interface/User';
import { storage } from '../../utils/storage';

export interface UserState {
    userData: Omit<User, 'accessToken'> | null,
    isLoggedIn: boolean,
    accessToken: string
}

interface UserPayload {
    accessToken: string;
    user: User;
}

const initialState: UserState = {
    userData: null,
    isLoggedIn: Boolean(storage.local.getItem('is_authenticated')),
    accessToken: ''
};

const userReducer = createSlice({
    name: 'user',
    initialState,
    reducers: {
        saveUser: (state, action: PayloadAction<UserPayload>) => {
            const { accessToken, user } = action.payload;
            storage.local.setItem('is_authenticated', Boolean(accessToken));
            state.userData = user;
            state.accessToken = accessToken;
            state.isLoggedIn = true;
        },
        removeUser: (state) => {
            storage.local.removeItem('is_authenticated');
            state.userData = null;
            state.accessToken = '';
            state.isLoggedIn = false;
        },
        setAccessToken: (state, action) => {
            const accessToken = action.payload?.accessToken;
            storage.local.setItem('is_authenticated', Boolean(accessToken));
            state.accessToken = accessToken;
            state.isLoggedIn = true;
        },
        setUser: (state, action) => {
            const user = action.payload?.user;
            state.userData = user;
        }
    }
});

export const { saveUser, removeUser, setAccessToken, setUser } = userReducer.actions;

export default userReducer.reducer;
