import { Button } from "antd";
import { MoonOutlined, SunOutlined } from '@ant-design/icons';
import { useDispatch } from "react-redux";
import { toggleTheme } from "../../../store/reducers/themeReducer";

const ThemeSwitch = ({ isDarkMode }: any) => {

    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(toggleTheme());
    };

    return (
        <Button className='nav-btn' onClick={handleClick}
            icon={isDarkMode ? <MoonOutlined /> : <SunOutlined />} />
    );
}

export default ThemeSwitch;
