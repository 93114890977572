import './Navbar.scss'
import { Badge, Button, Dropdown, Flex, MenuProps } from "antd";
import { FileTextOutlined, FolderOutlined, PlusOutlined, PoweroffOutlined, SettingOutlined, UserOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { Fragment, useEffect, useMemo, useState } from "react";
import EditLinkModal from "./components/EditLinkModal";
import Title from '../Title';
import Auth from '../Auth';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { UserState, removeUser } from '../../store/reducers/userReducer';
import { API } from '../../apis';
import axiosHttp from '../../axiosHttp';
import NoteSettingsModal from './components/NoteSettingsModal';
import Logo from '../Logo';
import { alertMessage } from '../../store/reducers/alertMessageReducer';
import { NoteState } from '../../store/reducers/noteReducer';
import { startLoading, stopLoading } from '../../store/reducers/loaderReducer';
import { accentColor } from '../../utils/styleModifier';
import ThemeSwitch from './components/ThemeSwitch';

const items: MenuProps['items'] = [
    {
        label: 'Profile',
        key: '1',
        icon: <UserOutlined />
    },
    {
        label: 'Notes',
        key: '2',
        icon: <FileTextOutlined />
    },
    {
        label: 'Stacks',
        key: '3',
        icon: <FolderOutlined />
    },
    {
        label: 'Logout',
        key: '4',
        icon: <PoweroffOutlined />
    }
];

function Navbar({ isDarkMode }: any) {

    const deviceType = useSelector((state: any) => state.device.type);
    const { isLoggedIn, userData }: UserState = useSelector((state: any) => state.user);
    const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
    const [isAuthOpen, setIsAuthOpen] = useState<boolean>(false);
    const [showNoteSettings, setShowNoteSettings] = useState<boolean>(false);
    const [isSettingsModalOpen, setisSettingsModalOpen] = useState<boolean>(false);
    const note: NoteState = useSelector((state: any) => state.note);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const showEditModal = () => {
        setIsEditModalOpen(true);
    };

    const closeEditModal = () => {
        setIsEditModalOpen(false);
    };

    const showAuthModal = () => {
        setIsAuthOpen(true);
    };

    const closeAuthModal = () => {
        setIsAuthOpen(false);
    };

    const showSettingsModal = () => {
        setisSettingsModalOpen(true);
    };

    const closeSettingsModal = () => {
        setisSettingsModalOpen(false);
    };

    const logoutUser = () => {
        dispatch(startLoading());
        axiosHttp.post(API.user.sign_out, {})
            .then((response) => {
                dispatch(removeUser());
                dispatch(alertMessage({ type: 'success', content: 'You have logged out!' }));
                navigate(`/`);
            })
            .catch((err) => console.log(err))
            .finally(() => dispatch(stopLoading()));
    }

    useEffect(() => {
        if (note.status === 'succeeded' && note?.isNoteOpen) {
            setShowNoteSettings(!(note.type === 'locked' || (note.data?.owner && !(isLoggedIn && note.data.owner._id === userData?._id))));
        } else {
            setShowNoteSettings(false);
        }
    }, [note, isLoggedIn, userData]);

    const renderNavButtons = () => {
        return (
            <Fragment>
                <Button className='nav-btn' icon={<PlusOutlined />} onClick={showEditModal} />
                {showNoteSettings && <Button className='nav-btn' icon={<SettingOutlined />} onClick={showSettingsModal} />}
                {
                    isLoggedIn ?
                        <Dropdown menu={menuProps} trigger={['click']} placement="bottom" arrow>
                            <Badge dot={true} color={accentColor()}>
                                <Button className='nav-btn' icon={<UserOutlined />} />
                            </Badge>
                        </Dropdown> :
                        <Button className='nav-btn' icon={<UserOutlined />} onClick={showAuthModal} />
                }
                <ThemeSwitch isDarkMode={isDarkMode} />
            </Fragment>
        )
    }

    const handleMenuClick: MenuProps['onClick'] = (e) => {
        switch (e.key) {
            case '1':
                navigate(`/profile`);
                break;
            case '2':
                navigate(`/notes`);
                break;
            case '3':
                navigate(`/stacks`);
                break;
            case '4':
                logoutUser();
                break;
        }
    };

    const menuProps = useMemo(() => ({
        items,
        onClick: handleMenuClick
    }), []);

    return (
        <Fragment>
            <div className="nav">
                <header>
                    <Flex justify="space-between" align="center">
                        <Flex gap="small" wrap="wrap">
                            <Logo size="small" height={30} width={30} />
                            <Title size={deviceType === 'mobile' ? 17 : 19} />
                        </Flex>
                        <Flex gap="small" wrap="wrap">
                            {renderNavButtons()}
                        </Flex>
                    </Flex>
                </header>
            </div>

            {
                isEditModalOpen &&
                <EditLinkModal isEditModalOpen={isEditModalOpen} deviceType={deviceType} isLoggedIn={isLoggedIn} setIsModalClose={closeEditModal} />
            }
            {
                isSettingsModalOpen &&
                <NoteSettingsModal isSettingsModalOpen={isSettingsModalOpen} deviceType={deviceType} isLoggedIn={isLoggedIn} setIsModalClose={closeSettingsModal} />
            }
            {
                isAuthOpen &&
                <Auth isAuthOpen={isAuthOpen} deviceType={deviceType} setIsAuthOpen={closeAuthModal} />
            }
        </Fragment>
    );
}

export default Navbar;