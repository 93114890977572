import { useSlate } from "slate-react"
import { Button } from "../../elements"
import { isMarkActive, toggleMark } from "../../helpers"

const FormatButton = ({ format, icon }: any) => {
    const editor = useSlate()
    return (
        <Button
            reversed
            active={isMarkActive(editor, format)}
            onClick={() => toggleMark(editor, format)}
        >
            {icon}
        </Button>
    )
}

export default FormatButton;