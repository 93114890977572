import { APP_NAME } from "./global";

type WindowWithDataLayer = Window & {
    gtag: Function;
};

declare const window: WindowWithDataLayer;

type TrackParams = {
    app_name?: string;
    screen_name: string;
}

type TrackerProps = {
    event_name: string;
    screen_name: string;
};

export const gTracker = ({ event_name, screen_name }: TrackerProps) => {
    if (window.location.hostname !== "localhost") {
        const params: TrackParams = { app_name: APP_NAME, screen_name: screen_name };
        window.gtag('event', event_name, params);
    }
}
