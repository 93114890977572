import { Outlet } from "react-router-dom";
import Navbar from "../../components/Navbar";
import { useSelector } from "react-redux";
import Loading from "../../components/Loading";
import Footer from "../../components/Footer";
import "./Layout.scss";
import { useEffect } from "react";
import axiosHttp from "../../axiosHttp";
import { API } from "../../apis";
import { useDispatch } from "react-redux";
import { UserState, setAccessToken, setUser } from "../../store/reducers/userReducer";

function Layout() {

    const isLoading = useSelector((state: any) => state.loader.loading);
    const isDarkMode = useSelector((state: any) => state.theme.darkMode);
    const { isLoggedIn, accessToken }: UserState = useSelector((state: any) => state.user);
    const dispatch: any = useDispatch();

    useEffect(() => {
        if (!accessToken && isLoggedIn) {
            axiosHttp.post(API.user.reauth, {})
                .then((response) => {
                    const { accessToken } = response.data;
                    dispatch(setAccessToken({ accessToken }));
                    return axiosHttp.get(API.user.profile)
                })
                .then((response) => {
                    const user = response.data?.user;
                    dispatch(setUser({ user }));
                })
                .catch((error) => console.log(error))
        }
    }, []);

    return (
        <div className="app">
            <Navbar isDarkMode={isDarkMode} />
            <div className='main'>
                {isLoading && <Loading />}
                <Outlet />
            </div>
            <Footer />
        </div>
    )
}

export default Layout;