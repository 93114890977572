import {
    Editor,
    Node,
    Point,
    Range,
    Element as SlateElement,
    Transforms
} from "slate"
import { getImageDimensions, insertImage, isImageUrl, isUrl, wrapLink } from "./helpers"
import { deserialize } from "./deserialize"

export const withLayout = (editor: any) => {
    const { normalizeNode } = editor
    editor.normalizeNode = ([node, path]: any) => {
        if (path.length === 0 && editor.children[0].children && editor.children[0].type === 'title') {
            for (const [child, childPath] of Node.children(editor, path)) {
                let type: string
                const slateIndex = childPath[0]
                const enforceType = (type: string) => {
                    if (SlateElement.isElement(child) && (child as any).type !== type) {
                        const newProperties: Partial<SlateElement> | any = { type }
                        Transforms.setNodes<SlateElement>(editor, newProperties, {
                            at: childPath,
                        })
                    }
                }
                switch (slateIndex) {
                    case 0:
                        type = 'title'
                        enforceType(type)
                        break
                    case 1:
                        type = 'paragraph'
                        enforceType(type)
                        break
                    default:
                        break
                }
            }
        }
        return normalizeNode([node, path])
    }
    return editor
}

export const withHtml = (editor: any) => {
    const { insertData, isInline, isVoid } = editor;
    editor.isInline = (element: any) => {
        return element.type === 'link' ? true : isInline(element)
    }
    editor.isVoid = (element: any) => {
        return element.type === 'image' ? true : isVoid(element)
    }
    editor.insertData = async (data: any) => {
        const html = data.getData('text/html');
        if (html) {
            const parsed = new DOMParser().parseFromString(html, 'text/html')
            const fragment: any = deserialize(parsed.body)
            const fragmentElement = fragment[0];
            if (fragmentElement.type === 'image') {
                fragment[0]['dimensions'] = await getImageDimensions(fragmentElement.url);
            }
            Transforms.insertFragment(editor, fragment);
            return
        }
        insertData(data)
    }
    return editor;
}

export const withChecklists = (editor: any) => {
    const { deleteBackward } = editor

    editor.deleteBackward = (...args: any[]) => {
        const { selection } = editor

        if (selection && Range.isCollapsed(selection)) {
            const [match]: any = Editor.nodes(editor, {
                match: (n: any) =>
                    !Editor.isEditor(n) &&
                    SlateElement.isElement(n) &&
                    (n as any).type === 'check-list-item'
            })

            if (match) {
                const [, path] = match
                const start = Editor.start(editor, path)

                if (Point.equals(selection.anchor, start)) {
                    const newProperties: Partial<SlateElement> | any = {
                        type: 'paragraph',
                    }
                    Transforms.setNodes(editor, newProperties, {
                        match: (n: any) =>
                            !Editor.isEditor(n) &&
                            SlateElement.isElement(n) &&
                            (n as any).type === 'check-list-item',
                    })
                    return
                }
            }
        }

        deleteBackward(...args)
    }

    return editor
}

export const withTables = (editor: any) => {
    const { deleteBackward, deleteForward, insertBreak } = editor;
    editor.deleteBackward = (unit: any) => {
        const { selection } = editor;
        if (selection && Range.isCollapsed(selection)) {
            const [cell] = Editor.nodes(editor, {
                match: n =>
                    !Editor.isEditor(n) &&
                    SlateElement.isElement(n) &&
                    (n as any).type === 'table-cell',
            })
            if (cell) {
                const [, cellPath] = cell
                const start = Editor.start(editor, cellPath)

                if (Point.equals(selection.anchor, start)) {
                    return
                }
            }
        }
        deleteBackward(unit);
    }
    editor.deleteForward = (unit: any) => {
        const { selection } = editor;
        if (selection && Range.isCollapsed(selection)) {
            const [cell] = Editor.nodes(editor, {
                match: n =>
                    !Editor.isEditor(n) &&
                    SlateElement.isElement(n) &&
                    (n as any).type === 'table-cell',
            })
            if (cell) {
                const [, cellPath] = cell
                const end = Editor.end(editor, cellPath)

                if (Point.equals(selection.anchor, end)) {
                    return
                }
            }
        }
        deleteForward(unit);
    }
    editor.insertBreak = () => {
        const { selection } = editor;
        if (selection) {
            const [table] = Editor.nodes(editor, {
                match: n =>
                    !Editor.isEditor(n) &&
                    SlateElement.isElement(n) &&
                    (n as any).type === 'table',
            })
            if (table) {
                return
            }
        }
        insertBreak();
    }
    return editor;
}

export const withImages = (editor: any) => {
    const { insertData, isVoid } = editor

    editor.isVoid = (element: any) => {
        return element.type === 'image' ? true : isVoid(element)
    }


    editor.insertData = async (data: any) => {
        const text = data.getData('text/plain')
        const { files } = data

        if (files && files.length > 0) {
            for (const file of files) {
                const reader = new FileReader()
                const [mime] = file.type.split('/')

                if (mime === 'image') {
                    reader.addEventListener('load', async () => {
                        const url: any = reader.result;
                        const dimensions = await getImageDimensions(url);
                        insertImage(editor, url, dimensions)
                    })

                    reader.readAsDataURL(file)
                }
            }
        } else if (isImageUrl(text)) {
            const dimensions = await getImageDimensions(text);
            insertImage(editor, text, dimensions);
        } else {
            insertData(data)
        }
    }

    return editor;
}

export const withInlines = (editor: any) => {
    const { insertData, insertText, isInline, isElementReadOnly, isSelectable } =
        editor

    editor.isInline = (element: any) =>
        ['link', 'button', 'badge'].includes(element.type) || isInline(element)

    editor.isElementReadOnly = (element: any) =>
        element.type === 'badge' || isElementReadOnly(element)

    editor.isSelectable = (element: any) =>
        element.type !== 'badge' && isSelectable(element)

    editor.insertText = (text: any) => {
        if (text && isUrl(text) && !isImageUrl(text)) {
            wrapLink(editor, text)
        } else {
            insertText(text)
        }
    }

    editor.insertData = (data: any) => {
        const text = data.getData('text/plain')

        if (text && isUrl(text) && !isImageUrl(text)) {
            wrapLink(editor, text)
        } else {
            insertData(data)
        }
    }

    return editor;
}