import { useSlate } from "slate-react"
import { isMarkActive, toggleMark } from "../../helpers"
import { Button } from "../../elements"

const MarkButton = ({ format, icon }: any) => {
    const editor = useSlate()
    return (
        <Button
            active={isMarkActive(editor, format)}
            onMouseDown={(event: any) => {
                event.preventDefault()
                toggleMark(editor, format)
            }}
        >
            {icon}
        </Button>
    )
}

export default MarkButton;