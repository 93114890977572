import { InlineChromiumBugfix } from "../helpers";
import CheckListItemElement from "./CheckListItemElement";
import ImageElement from "./ImageElement";

const Element = (props: any) => {
    const { attributes, children, element } = props;
    const style = { textAlign: element.align };
    switch (element.type) {
        case 'quote':
            return <blockquote style={style} {...attributes}>{children}</blockquote>
        case 'code-block':
            return (
                <div {...attributes}
                    className="code-block"
                    spellCheck={false}>
                    {children}
                </div>
            )
        case 'bulleted-list':
            return <ul style={style} {...attributes}>{children}</ul>
        case 'heading-one':
            return <h1 style={style} {...attributes}>{children}</h1>
        case 'heading-two':
            return <h2 style={style} {...attributes}>{children}</h2>
        case 'heading-three':
            return <h3 style={style} {...attributes}>{children}</h3>
        case 'heading-four':
            return <h4 style={style} {...attributes}>{children}</h4>
        case 'heading-five':
            return <h5 style={style} {...attributes}>{children}</h5>
        case 'heading-six':
            return <h6 style={style} {...attributes}>{children}</h6>
        case 'list-item':
            return <li style={style} {...attributes}>{children}</li>
        case 'numbered-list':
            return <ol style={style} {...attributes}>{children}</ol>
        case 'link':
            return (
                <a style={style} href={element.url} {...attributes} className="cursor-pointer"
                    onClick={(e) => { if (e.metaKey) { window.open(element.url, '_blank') } }}>
                    <InlineChromiumBugfix />
                    {children}
                    <InlineChromiumBugfix />
                </a>
            )
        case 'check-list-item':
            return <CheckListItemElement style={style} {...props} />
        case 'image':
            return <ImageElement style={style} {...props} />
        case 'title':
            return <h1 style={style} {...attributes}>{children}</h1>
        case 'paragraph':
            return <p style={style} {...attributes}>{children}</p>
        case 'author':
            return <em style={{ ...style, display: 'block' }} className="font-weight-500" {...attributes}>{children}</em>
        case 'date':
            return <em style={{ ...style, opacity: 0.7, display: 'block', fontSize: '14px' }} {...attributes}>{children}</em>
        case 'table':
            return (
                <table>
                    <tbody {...attributes}>{children}</tbody>
                </table>
            )
        case 'table-body':
            return <tbody {...attributes}>{children}</tbody>
        case 'table-header':
            return <th {...attributes} colspan={element.colspan}>{children}</th>
        case 'table-row':
            return <tr {...attributes}>{children}</tr>
        case 'table-cell':
            return <td {...attributes}>{children}</td>
        default:
            return <p style={style} {...attributes}>{children}</p>
    }
}

export default Element;