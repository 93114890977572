export const API = {
    note: {
        user_notes: '/api/notes',
        operate_by_id: (noteId: string) => `/api/notes/${noteId}`,
        validate: (noteId: string) => `/api/notes/${noteId}/validate`,
        document: {
            operate_by_id: (documentId: string) => `/api/notes/document/${documentId}`
        }
    },
    user: {
        sign_up: '/api/users/sign_up',
        sign_in: '/api/users/sign_in',
        sign_out: '/api/users/sign_out',
        forgot_password: '/api/users/forgot_password',
        change_password: '/api/users/change_password',
        reset_password: (resetToken: string) => `/api/users/reset_password/${resetToken}`,
        update: '/api/users',
        reauth: '/api/users/reauth',
        profile: '/api/users/profile',
        master_sign_out: '/api/users/master_sign_out',
        verify_email: (verificationToken: string) => `/api/users/verify_email/${verificationToken}`,
        resend_verification_mail: '/api/users/resend_verification_mail'
    },
    contact_us: {
        send_query: '/api/user_query'
    },
    stack: {
        get: '/api/stacks',
        create: '/api/stacks',
        operate_by_id: (stackId: string) => `/api/stacks/${stackId}`,
        note_options: '/api/stacks/note_options',
        stack_notes: (stackLinkID: string) => `/api/stacks/${stackLinkID}/notes`
    }
}