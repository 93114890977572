import { Button, Divider, Form, Input } from "antd";
import { Controller, useForm } from "react-hook-form";
import { EMAIL_PATTERN } from "../../../utils/global";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { API } from "../../../apis";
import { useDispatch } from "react-redux";
import { saveUser } from "../../../store/reducers/userReducer";
import axiosHttp from "../../../axiosHttp";
import { useState } from "react";
import { alertMessage } from "../../../store/reducers/alertMessageReducer";
import { AuthAction } from "../AuthAction";

type FormData = {
    email: string;
    password: string;
}

function SignIn({ changeAuthType, closeAuth }: any) {

    const [isLoading, setIsloading] = useState<boolean>(false);
    const [isVerifyLoading, setIsVerifyloading] = useState<boolean>(false);
    const [showVerifyBtn, setShowVerifyBtn] = useState<boolean>(false);
    const dispatch = useDispatch();
    const { control, handleSubmit, watch, formState: { errors } } = useForm<FormData>({
        defaultValues: {
            email: '',
            password: ''
        }
    });
    const email = watch('email');

    const onSubmit = (data: FormData) => {
        setIsloading(true);
        axiosHttp.post(API.user.sign_in, data)
            .then((response) => {
                dispatch(saveUser(response.data));
                dispatch(alertMessage({ type: 'success', content: 'You have signed in successfully!' }));
                closeAuth();
            })
            .catch((err) => {
                console.log(err)
                const { error } = err.response.data;
                if (error === 'User verification pending') {
                    setShowVerifyBtn(true);
                }
            })
            .finally(() => setIsloading(false));
    };

    const goToSignUp = () => {
        changeAuthType(AuthAction.SignUp);
    }

    const goToForgotPassword = () => {
        changeAuthType(AuthAction.ForgotPassword);
    }

    const resendVerificationLink = () => {
        if (!email) {
            dispatch(alertMessage({ type: 'error', content: "Email not provided, please enter email id in above email field", duration: 8 }));
            return;
        }
        setIsVerifyloading(true);
        axiosHttp.post(API.user.resend_verification_mail, { email }, {
            headers: {
                "X-verify-email": `${document.location.origin}/verify-email`
            }
        }).then((response) => {
            dispatch(alertMessage({ type: 'success', content: "Email verification sent. Please verify your account by clicking the link in the email we've sent to you.", duration: 8 }));
            setShowVerifyBtn(false);
        }).catch((err) => console.log(err)).finally(() => setIsVerifyloading(false));
    }

    return (
        <Form layout="vertical">
            <Form.Item label="Email" validateStatus={errors.email ? 'error' : ''}
                help={errors.email ? errors.email.message : null} required>
                <Controller
                    name="email"
                    control={control}
                    rules={{
                        required: 'Email is required', pattern: {
                            value: EMAIL_PATTERN,
                            message: "Enter valid email",
                        }
                    }}
                    render={({ field }) => (
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} {...field} placeholder="Email" type="email" size="large" autoComplete="disabled" />
                    )}
                />
            </Form.Item>
            <Form.Item label="Password" className="m-t-1" validateStatus={errors.password ? 'error' : ''}
                help={errors.password ? errors.password.message : null} required>
                <Controller
                    name="password"
                    control={control}
                    rules={{
                        required: 'Password is required',
                        minLength: {
                            value: 6,
                            message: 'Password should have minimum 6 characters'
                        }
                    }}
                    render={({ field }) => (
                        <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} {...field} placeholder="Password" type="password" size="large" autoComplete="new-password" />
                    )}
                />
            </Form.Item>

            <Form.Item className="m-t-2">
                <Button style={{ width: '100%' }} type="primary" htmlType="submit" loading={isLoading} size="large" onClick={handleSubmit(onSubmit)}>
                    Sign In
                </Button>
                <Form.Item className="m-b-0 text-align-right">
                    <div className="text-accent-color cursor-pointer display-inline" onClick={goToForgotPassword}>Forgot password</div>
                </Form.Item>
                {showVerifyBtn && <Form.Item className="text-align-center m-b-0 m-t-1">
                    <Button type="primary" onClick={resendVerificationLink} loading={isVerifyLoading} ghost>
                        Resend verification link
                    </Button>
                </Form.Item>}
            </Form.Item>

            <Divider plain><span className="text-light">Or</span></Divider>

            <Form.Item style={{ textAlign: 'center' }}>
                <Button style={{ marginTop: '1rem' }} type="primary" onClick={goToSignUp} size="large" ghost>
                    Sign Up Now!
                </Button>
            </Form.Item>
        </Form>
    );
}

export default SignIn;