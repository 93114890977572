export const textCapitalize = (text: string): string => {
    return text.charAt(0).toUpperCase() + text.slice(1);
}

export function accentColor(): string {
    return window.getComputedStyle(document.documentElement).getPropertyValue('--accent-color');
}

export function textAreaBackgound(): string {
    return window.getComputedStyle(document.documentElement).getPropertyValue('--text-area-bg');
}

export function appBackgound(): string {
    return window.getComputedStyle(document.documentElement).getPropertyValue('--background-color');
}

export function borderColor(): string {
    return window.getComputedStyle(document.documentElement).getPropertyValue('--border-color');
}

export function textColor(): string {
    return window.getComputedStyle(document.documentElement).getPropertyValue('--text-color');
}