import { Link } from "react-router-dom";
import "./Logo.scss";
import { useEffect, useState } from "react";

interface ImagePath {
    webp: string;
    png: string;
}

const imagePaths: ImagePath = {
    webp: '/images/logo192.webp',
    png: '/images/logo192.png'
}

const Logo = ({ height = 30, width = 30, size = 'small', cursor = true }: any) => {

    const [imagePath, setImagePath] = useState<ImagePath>(imagePaths);

    useEffect(() => {
        const logoSize = size === 'small' ? '192' : '440';
        setImagePath({
            webp: `/images/logo${logoSize}.webp`,
            png: `/images/logo${logoSize}.png`
        });
    }, [size]);

    const renderLogo = () => {
        return (
            <div className="logo">
                <picture >
                    <source srcSet={imagePath.webp} type="image/webp" />
                    <source srcSet={imagePath.png} type="image/png" />
                    <img src={imagePath.png} alt="bakstak-logo" height={`${height}px`} width={`${width}px`} />
                </picture>
            </div >
        );
    }

    return (
        cursor ?
            <Link to="/" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                {renderLogo()}
            </Link>
            : renderLogo()
    );
}

export default Logo;
