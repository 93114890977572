import { configureStore } from '@reduxjs/toolkit';
import themeReducer from './reducers/themeReducer';
import deviceTypeReducer from './reducers/deviceTypeReducer';
import userReducer from './reducers/userReducer';
import noteReducer from './reducers/noteReducer';
import alertMessageReducer from './reducers/alertMessageReducer';
import loaderReducer from './reducers/loaderReducer';

const store = configureStore({
  reducer: {
    theme: themeReducer,
    device: deviceTypeReducer,
    user: userReducer,
    note: noteReducer,
    alert: alertMessageReducer,
    loader: loaderReducer
  },
})

export default store;
