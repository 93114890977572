import "./Loading.scss";

function Loading() {

    return (
        <div className="progress">
            <div className="indeterminate"></div>
        </div>
    )
}

export default Loading;