import { LockOutlined } from "@ant-design/icons";
import { Button, Card, Flex, Form, Input } from "antd";
import { Controller, useForm } from "react-hook-form";
import axiosHttp from "../../axiosHttp";
import { API } from "../../apis";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { alertMessage } from "../../store/reducers/alertMessageReducer";
import { setNoteType } from "../../store/reducers/noteReducer";

type FormData = {
    password: string
}

function NoteValidator({ noteData }: any) {

    const [isLoading, setIsloading] = useState<boolean>(false);
    const { control, handleSubmit, formState: { errors } } = useForm<FormData>({
        defaultValues: {
            password: ''
        }
    });
    const dispatch = useDispatch();

    const onSubmit = (data: FormData) => {
        setIsloading(true);
        axiosHttp.put(API.note.validate(noteData._id), data)
            .then((response) => {
                if (response.data.validated) {
                    dispatch(alertMessage({ type: 'success', content: 'Note validated successfully!' }));
                    dispatch(setNoteType('default'));
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setIsloading(false);
            });
    }

    return (
        <Flex justify="center" align="center">
            <Card title="Note Validation" style={{ minWidth: '20rem', width: '40%' }} className="m-t-6">
                <Form layout="vertical" className="m-t-1">
                    <Form.Item label="Note Password" className="m-t-1" validateStatus={errors.password ? 'error' : ''} required
                        help={errors.password ? errors.password.message : null}>
                        <Controller
                            name="password"
                            control={control}
                            rules={{
                                required: 'Password is required',
                                minLength: {
                                    value: 6,
                                    message: 'Password should have minimum 6 characters'
                                }
                            }}
                            render={({ field }) => (
                                <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} {...field} placeholder="Password" type="password" autoComplete="new-password" size="large" />
                            )}
                        />
                    </Form.Item>
                    <Form.Item className="m-t-2">
                        <Button style={{ width: '100%' }} type="primary" htmlType="submit" loading={isLoading} onClick={handleSubmit(onSubmit)} size="large">
                            Validate
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </Flex>
    );
}

export default NoteValidator;