import {
    Element as SlateElement,
    Editor,
    Transforms,
    Descendant,
    Range
} from "slate"

export const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];
export const LIST_TYPES = ['numbered-list', 'bulleted-list'];
export type LinkElement = { type: 'link'; url: string; children: Descendant[] }

type EmptyText = {
    text: string
}

type ImgDimensions = {
    height: number,
    width: number
}

type ImageElement = {
    type: 'image',
    url: string,
    children: EmptyText[],
    dimensions?: ImgDimensions
}

const imageExtensions = [
    'png', 'jpg', 'jpeg', 'ico', 'webp', 'heif', 'gif', 'svg', 'tiff', 'bmp'
];

const protocolAndDomainRE = /^(?:\w+:)?\/\/(\S+)$/;
const localhostDomainRE = /^localhost[\:?\d]*(?:[^\:?\d]\S*)?$/
const nonLocalhostDomainRE = /^[^\s\.]+\.\S{2,}$/;


export const isBlockActive = (editor: any, format: any, blockType = 'type') => {
    const { selection } = editor
    if (!selection) return false

    const [match] = Array.from(
        Editor.nodes(editor, {
            at: Editor.unhangRange(editor, selection),
            match: (n) =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                (n as any)[blockType] === format,
        })
    )

    return !!match
}

export const toggleBlock = (editor: any, format: string) => {
    const isActive = isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
    )
    const isList = LIST_TYPES.includes(format)

    Transforms.unwrapNodes(editor, {
        match: n =>
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            LIST_TYPES.includes((n as any).type) &&
            !TEXT_ALIGN_TYPES.includes(format),
        split: true,
    })
    let newProperties: any//Partial<SlateElement>
    if (TEXT_ALIGN_TYPES.includes(format)) {
        newProperties = {
            align: isActive ? undefined : format,
        }
    } else {
        newProperties = {
            type: isActive ? 'paragraph' : isList ? 'list-item' : format,
        }
    }
    Transforms.setNodes<SlateElement>(editor, newProperties)

    if (!isActive && isList) {
        const block: any = { type: format, children: [] }
        Transforms.wrapNodes(editor, block)
    }
}

export const toggleMark = (editor: any, format: any) => {
    const isActive = isMarkActive(editor, format)

    if (isActive) {
        Editor.removeMark(editor, format)
    } else {
        Editor.addMark(editor, format, true)
    }
}

export const isMarkActive = (editor: any, format: any) => {
    const marks: any = Editor.marks(editor)
    return marks ? marks[format] === true : false
}

export const getImageDimensions = (url: string): Promise<ImgDimensions> => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve({
            width: img.width,
            height: img.height
        });
        img.onerror = (error) => reject(error);
        img.src = url;
    });
}

export const insertImage = (editor: any, url: string, dimensions?: ImgDimensions) => {
    const text = { text: '' }
    const image: ImageElement = { type: 'image', url, children: [text], dimensions }
    Transforms.insertNodes(editor, image)
    const node = {
        type: 'paragraph',
        children: [{ text: '' }],
    };
    Transforms.insertNodes(editor, node);
}

export const isImageUrl = (url: string) => {
    if (!url) return false
    if (!isUrl(url)) return false
    const ext = new URL(url).pathname.split('.').pop();
    return imageExtensions.includes(ext?.toLowerCase()!);
}

export const isUrl = (string: string) => {
    if (typeof string !== 'string') {
        return false;
    }

    const match = string.match(protocolAndDomainRE);
    if (!match) {
        return false;
    }

    const everythingAfterProtocol = match[1];
    if (!everythingAfterProtocol) {
        return false;
    }

    if (localhostDomainRE.test(everythingAfterProtocol) ||
        nonLocalhostDomainRE.test(everythingAfterProtocol)) {
        return true;
    }

    return false;
}

export const insertLink = (editor: any, url: string) => {
    if (editor.selection) {
        wrapLink(editor, url)
    }
}

export const isLinkActive = (editor: any) => {
    const [link] = Editor.nodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && (n as any).type === 'link',
    })
    return !!link
}

const unwrapLink = (editor: any) => {
    Transforms.unwrapNodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && (n as any).type === 'link',
    })
}

export const wrapLink = (editor: any, url: string) => {
    if (isLinkActive(editor)) {
        unwrapLink(editor)
    }

    const { selection } = editor
    const isCollapsed = selection && Range.isCollapsed(selection)
    const link: LinkElement = {
        type: 'link',
        url,
        children: isCollapsed ? [{ text: url }] : [],
    }

    if (isCollapsed) {
        Transforms.insertNodes(editor, link)
    } else {
        Transforms.wrapNodes(editor, link, { split: true })
        Transforms.collapse(editor, { edge: 'end' })
    }
}

export const InlineChromiumBugfix = () => (
    <span
        contentEditable={false}
        style={{ fontSize: '0px' }}>
        {String.fromCodePoint(160)}
    </span>
)