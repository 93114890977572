import { createSlice } from '@reduxjs/toolkit';

interface LoaderState {
    loading: boolean;
}

const initialState: LoaderState = {
    loading: false
};

const loaderReducer = createSlice({
    name: 'loader',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.loading = true;
        },
        stopLoading: (state) => {
            state.loading = false;
        }
    }
});

export const { startLoading, stopLoading } = loaderReducer.actions;

export default loaderReducer.reducer;