import { Button, Form, Input, Modal, Space, Tooltip } from "antd";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { uniqueIdGenerator } from "../../../utils/uniqueId";
import { RedoOutlined } from "@ant-design/icons";
import { urlSafeModifier } from "../../../utils/urlSafeModifier";

type FormData = {
    link: string
}

function EditLinkModal({ isEditModalOpen, deviceType, setIsModalClose }: any) {

    const { control, handleSubmit, setValue, formState: { errors } } = useForm<FormData>({
        defaultValues: {
            link: uniqueIdGenerator()
        }
    });
    const navigate = useNavigate();

    const onSubmit = ({ link }: FormData) => {
        navigate(urlSafeModifier(link), { replace: false });
        setIsModalClose();
    };

    const handleCancel = () => {
        setIsModalClose();
    };

    const generateNewLink = () => {
        const newLink = uniqueIdGenerator();
        setValue('link', newLink, { shouldValidate: true })
    }

    return (
        <Modal title="New Note" width={deviceType === 'mobile' ? '100%' : 520} open={isEditModalOpen} onCancel={handleCancel} footer={[
            <Button key="submit" type="primary" style={{ width: deviceType === 'mobile' ? '100%' : '150px' }} onClick={handleSubmit(onSubmit)}>Open</Button>]}>

            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                <Form layout="vertical" className="m-t-1">
                    <Form.Item label="Note Link" validateStatus={errors.link ? 'error' : ''}
                        extra={<div style={{ padding: '6px 4px' }}>{'Create a new link or open an existing one.'}</div>}
                        help={errors.link ? errors.link.message as string : null}>
                        <Controller
                            name="link"
                            control={control}
                            defaultValue=""
                            rules={{ required: 'Link is required' }}
                            render={({ field }) => (
                                <Space.Compact style={{ width: '100%' }}>
                                    <Input style={{opacity: '0.8'}} {...field} placeholder="Link" size={'large'} />
                                    <Tooltip title="Generate new link">
                                        <Button type="primary" icon={<RedoOutlined />} onClick={generateNewLink} size={'large'} />
                                    </Tooltip>
                                </Space.Compact>
                            )}
                        />
                    </Form.Item>
                </Form>
            </Space>

        </Modal>
    );
}

export default EditLinkModal;