import {
    AlignCenterOutlined,
    AlignLeftOutlined,
    AlignRightOutlined,
    BoldOutlined,
    CheckSquareOutlined,
    CodeOutlined,
    FontSizeOutlined,
    ItalicOutlined,
    OrderedListOutlined,
    UnderlineOutlined,
    UnorderedListOutlined
} from "@ant-design/icons";
import { Toolbar } from "../elements";
import BlockButton from "./Buttons/BlockButton";
import DropButton from "./Buttons/DropButton";
import MarkButton from "./Buttons/MarkButton";
// import InsertImageButton from "./Buttons/InsertImageButton";
// import AddLinkButton from "./Buttons/AddLinkButton";

const EditorToolbar = () => {
    return (
        <Toolbar>
            <MarkButton format="bold" icon={<BoldOutlined />} />
            <MarkButton format="italic" icon={<ItalicOutlined />} />
            <MarkButton format="underline" icon={<UnderlineOutlined />} />
            <BlockButton format="code-block" icon={<CodeOutlined />} />
            <DropButton icon={<FontSizeOutlined />} />
            <BlockButton format="check-list-item" icon={<CheckSquareOutlined />} />
            <BlockButton format="numbered-list" icon={<OrderedListOutlined />} />
            <BlockButton format="bulleted-list" icon={<UnorderedListOutlined />} />
            <BlockButton format="left" icon={<AlignLeftOutlined />} />
            <BlockButton format="center" icon={<AlignCenterOutlined />} />
            <BlockButton format="right" icon={<AlignRightOutlined />} />
            {/* <InsertImageButton />  */}
            {/* <AddLinkButton /> */}
        </Toolbar>
    )
}

export default EditorToolbar;