import './Footer.scss'
import { APP_NAME } from '../../utils/global';
import { Link } from 'react-router-dom';

const appName = APP_NAME;

function Footer() {
    return (
        <div className="footer">
            <footer>
                <ul>
                    <li>
                        © 2024 {appName}
                    </li>
                    <li>
                        <Link to="about-us">About Us</Link>
                    </li>

                    <li>
                        <Link to="contact-us">Contact Us</Link>
                    </li>

                    <li>
                        <Link to="privacy-policy">Privacy</Link>
                    </li>

                    <li>
                        <Link to="terms-and-conditions">Terms</Link>
                    </li>
                </ul>
            </footer>
        </div>
    );
}

export default Footer;