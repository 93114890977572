const Leaf = ({ attributes, children, leaf }: any) => {

    const { text, ...rest } = leaf;

    if (leaf.bold) {
        children = <strong>{children}</strong>
    }

    if (leaf.code) {
        children = <code>{children}</code>
    }

    if (leaf.italic) {
        children = <em>{children}</em>
    }

    if (leaf.underline) {
        children = <u>{children}</u>
    }

    // return <span {...attributes}>{children}</span>
    return <span {...attributes} className={Object.keys(rest).join(' ')}>
        {children}
    </span>
}

export default Leaf;