import { Button } from "antd";
import { Note } from "../../../interface/Note";
import Moment from 'moment';
import { LinkOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { alertMessage } from "../../../store/reducers/alertMessageReducer";
import { useDispatch } from "react-redux";

interface Props {
    note: Note
}

function NoteCard({ note }: Props) {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const copyToClipboard = async () => {
        const link = `${window.location.origin.toString()}/${note?.noteLinkID}`;
        dispatch(alertMessage({ type: 'info', content: 'Link copied to clipboard' }))
        return await navigator.clipboard.writeText(link);
    }

    const navigateToNote = () => {
        navigate(`/${note?.noteLinkID}`);
    }

    return (
        <div className="note-card">
            <div className="note-header" onClick={navigateToNote}>
                <div className="note-title">{note?.metaTitle || note?.title}</div>
                {note?.metaDescription && <div className="note-description">{note?.metaDescription}</div>}
            </div>
            <div className="note-footer">
                <div className="note-date">{Moment(note?.updatedOn).format('DD MMMM, YYYY')}</div>
                <div className="note-link">
                    <Button className="text-accent-color" icon={<LinkOutlined />} onClick={copyToClipboard} size="small" />
                </div>
            </div>
        </div>
    )
}

export default NoteCard;