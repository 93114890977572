import { createSlice } from '@reduxjs/toolkit';


export interface Alert {
    type: string;
    content: string;
    duration?: number;
}

interface ALertState {
  data: Alert | null;
}

const initialState: ALertState = {
    data: null
};

const alertMessageReducer = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    alertMessage: (state, action) => {
      state.data = action.payload;
    }
  }
});

export const { alertMessage } = alertMessageReducer.actions;

export default alertMessageReducer.reducer;