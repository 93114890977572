import { message } from "antd";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { Alert, alertMessage } from "../../store/reducers/alertMessageReducer";
import { useDispatch } from "react-redux";

function AlertMessage() {

    const [messageApi, contextHolder] = message.useMessage();
    const alert: Alert = useSelector((state: any) => state.alert.data);
    const dispatch = useDispatch();

    useEffect(() => {
        if (alert) {
            switch (alert.type) {
                case 'success':
                    messageApi.open({ type: 'success', content: alert.content, duration: alert.duration || 3 });
                    break;
                case 'error':
                    messageApi.open({ type: 'error', content: alert.content, duration: alert.duration || 3 });
                    break;
                case 'warning':
                    messageApi.open({ type: 'warning', content: alert.content, duration: alert.duration || 3 });
                    break;
                case 'info':
                    messageApi.open({ type: 'info', content: alert.content, duration: alert.duration || 3, className: 'info-msg' });
                    break;
                case 'loading':
                    messageApi.open({ type: 'loading', content: alert.content, duration: alert.duration || 3 });
                    break;
                default:
                    messageApi.open({ type: 'info', content: alert.content, duration: alert.duration || 3 });
                    break;
            }
        }

        return () => {
            dispatch(alertMessage(null));
        }
    }, [alert]);

    return contextHolder;
}

export default AlertMessage;