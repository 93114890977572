interface Storage {
    type: 'localStorage' | 'sessionStorage';
    key: string;
    data?: any;
    json?: boolean;
}

const setItem = ({ type, key, data, json }: Storage) => {
    if (typeof window !== "undefined" && window.localStorage) {
        const storage = type === 'localStorage' ? localStorage : sessionStorage;
        storage.setItem(key, json ? JSON.stringify(data) : data);
    }
};

const getItem = ({ type, key, json }: Storage) => {
    if (typeof window !== "undefined" && window.localStorage) {
        const storage = type === 'localStorage' ? localStorage : sessionStorage;
        let _data: any = null;
        const storedData: any = storage.getItem(key);
        try {
            _data = json ? JSON.parse(storedData) : storedData;
            return _data;
        } catch (error) {
            if (error instanceof SyntaxError) removeItem({ type, key });
            return null;
        }
    } else {
        return null;
    }
};

const removeItem = ({ type, key }: Storage) => {
    if (typeof window !== "undefined" && window.localStorage) {
        const storage = type === 'localStorage' ? localStorage : sessionStorage;
        storage.removeItem(key);
    }
};

export const storage = {
    local: {
        setItem: (key: string, data: any, json: boolean = false) => setItem({ type: 'localStorage', key, data, json }),
        getItem: (key: string, json: boolean = false) => getItem({ type: 'localStorage', key, json }),
        removeItem: (key: string) => removeItem({ type: 'localStorage', key })
    },
    session: {
        setItem: (key: string, data: any, json: boolean = false) => setItem({ type: 'sessionStorage', key, data, json }),
        getItem: (key: string, json: boolean = false) => getItem({ type: 'sessionStorage', key, json }),
        removeItem: (key: string) => removeItem({ type: 'sessionStorage', key })
    }
};