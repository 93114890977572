import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { API } from '../../apis'
import axiosHttp from '../../axiosHttp'
import { Document, Note, NoteStack } from '../../interface/Note'

interface FetchNote {
    noteId: string;
    userId?: string;
}

export const fetchNote = createAsyncThunk('fetchNote', async ({ noteId, userId }: FetchNote) => {
    const response = await axiosHttp.get(API.note.operate_by_id(noteId), { params: userId ? { userId: userId } : {} });
    return response.data;
});

export const fetchUserNotes = createAsyncThunk('fetchUserNotes', async (params: any) => {
    const response = await axiosHttp.get(API.note.user_notes, { params: params })
    return response.data;
});

export interface NoteState {
    data: Note | null;
    document: Document | null;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    isNoteOpen: boolean;
    error: string | undefined;
    type: 'default' | 'locked' | 'published' | 'none';
    notes: Note[] | [];
    stack: NoteStack | null;
}

const initialState = {
    data: null,
    document: null,
    status: 'idle',
    isNoteOpen: false,
    error: '',
    type: 'none',
    notes: [],
    stack: null
} satisfies NoteState as NoteState

const noteReducer = createSlice({
    name: 'note',
    initialState,
    reducers: {
        logoutNote(state) {
            state.data = null;
        },
        noteOpen(state, action) {
            state.isNoteOpen = action.payload;
        },
        updateNote(state, action) {
            state.data = action.payload;
        },
        removeNote(state, action) {
            const noteIndex = state.notes.findIndex((note: Note) => note._id === action.payload);
            if (noteIndex > -1) {
                state.notes.splice(noteIndex, 1);
            }
        },
        resetNotes(state) {
            state.notes = [];
        },
        setNoteType(state, action) {
            state.type = action.payload;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchNote.pending, (state) => {
                state.status = 'loading';
                state.type = 'none';
                state.data = null;
                state.document = null;
            })
            .addCase(fetchNote.fulfilled, (state, action) => {
                const note: Note = action.payload.note;
                const document: Document = action.payload.document || null;
                state.data = note;
                state.document = document;
                state.status = 'succeeded';
                state.type = note ? (note.isSEO ? 'published' : (note.isProtected ? 'locked' : 'default')) : 'default';
            })
            .addCase(fetchNote.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                state.type = 'none';
                state.data = null;
                state.document = null;
            })
            .addCase(fetchUserNotes.pending, (state) => {
                state.status = 'loading';
                state.notes = [];
                state.stack = null;
            })
            .addCase(fetchUserNotes.fulfilled, (state, action) => {
                state.notes = action.payload.notes;
                state.stack = action.payload.stack;
                state.status = 'succeeded';
            })
            .addCase(fetchUserNotes.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                state.notes = [];
                state.stack = null;
            })
    }
});

export const { logoutNote, noteOpen, updateNote, removeNote, resetNotes, setNoteType } = noteReducer.actions;

export default noteReducer.reducer;
